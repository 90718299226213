import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import CleanLayout from "../layouts/Clean";
import {
  Intro,
  SEO,
  PostPreview,
  Text,
  Label,
  Button,
  Title,
} from "components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import storageAvailable from "../helpers/storageAvailable";

const pageProperties = {
  title: "Pillole.",
  // subtitle: 'Il blog di Trame Digitali.',
  description:
    "Ne sappiamo a pacchi. Non ci credi? Inizia a conoscerci leggendo il nostro blog e trova ispirazioni.",
};

const GET_POSTS_BY_AUTHOR = `
  query getPostsByAuthor($limit: String!, $slug: String!) {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "it" }, author: { slug: { eq: $slug } } }
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
    ) {
      nodes {
        slug
        title
        image {
          gatsbyImageData
        }
        author {
          name
          tagline
        }
        category {
          color
          name
          slug
        }
        subtitle {
          subtitle
        }
      }
    }
  }
`;

const BlogList = ({ pageContext, data }) => {
  const {
    allContentfulBlogPost: { nodes: posts },
    allContentfulCategory: { nodes: cats },
    allContentfulAuthor: { nodes: auths },
  } = data;
  const { categories, authors, currentPage, numPages } = pageContext;

  const [shownAuthor, setShownAuthor] = useState(null);
  const [shownTagline, setShownTagline] = useState(null);
  const [shownTalksAbout, setShownTalksAbout] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState(posts);

  const handleAuthorClick = (slug) => {};

  useEffect(() => {}, [shownTalksAbout]);

  useEffect(() => {
    if (!storageAvailable) {
      return;
    }

    // if(pageContext.blablabla)

    if (!localStorage.getItem("last-post")) {
      localStorage.setItem("last-post", posts[0].id);
      return;
    }
  }, []);

  const renderPreviousPages = (currentPage, numPages) => {
    let previousPages = [];

    for (let i = currentPage - 1; i > 0; i--) {
      const prePags = (
        <Link to={`${i === 1 ? "/blog/" : `/blog/${i}/`}`}>
          <span>{i}</span>
        </Link>
      );
      previousPages.push(prePags);
    }

    return previousPages;
  };
  const renderNextPages = (currentPage, numPages) => {
    let nextPages = [];
    for (let i = currentPage + 1; i <= numPages; i++) {
      const prePags = (
        <Link to={`/blog/${i}/`}>
          <span>{i}</span>
        </Link>
      );
      nextPages.push(prePags);
    }

    return nextPages;
  };

  return (
    <CleanLayout>
      <SEO
        keywords={[
          `Trame Digitali`,
          `Web marketing`,
          `e-commerce`,
          `siti web`,
          `Web Agency`,
          `SEO`,
          `next.js`,
          `Jam stack`,
          `gatsby`,
          `tailwind`,
          `react`,
          `Prato`,
          `Firenze`,
          `Pistoia`,
          `Lucca`,
          `Milano`,
        ]}
        title={pageProperties.title}
        description={pageProperties.description}
      />

      <div className="w-screen px-8 md:px-28">
        <Intro {...pageProperties} />
      </div>
      <section>
        <div className="my-8">
          <PostPreview
            {...posts[0]}
            image={getImage(posts[0].imageWithoutTitle ?? posts[0]?.image)}
            version="hero"
          />
        </div>
      </section>
      <div className="w-screen pl-8 md:px-28 my-8">
        <section className="">
          <div className="flex flex-col">
            <div className="flex flex-col my-4">
              <div className="my-1">
                <Text size="small" opaque>
                  Categoria
                </Text>
              </div>
              <div className="overflow-x-auto flex py-2 px-1">
                {cats.map(({ slug, color, name }) => {
                  const cat = categories.filter(
                    ({ fieldValue }) => fieldValue === slug
                  );
                  return (
                    cat[0]?.totalCount && (
                      <div className="mr-4">
                        <Link to={`/${slug}/`}>
                          <Label
                            key={slug}
                            name={name}
                            color={color}
                            number={cat[0]?.totalCount}
                          />
                        </Link>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            <div className="my-8">
              <div className="my-1 ">
                <Text size="small" opaque>
                  Autori
                </Text>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="flex mr-16">
                  {auths?.map(({ slug, picture, name, tagline, blog_post }) => {
                    const image = getImage(picture);
                    return (
                      <div className="" key={slug}>
                        {image && (
                          <a
                            href={`/consulente/${slug}/`}
                            onClick={() => handleAuthorClick(slug)}
                          >
                            <div className="h-12 w-12 -mr-2 hover:mx-2  transition-all duration-300">
                              <GatsbyImage
                                alt={`${name} image`}
                                imgClassName="border-4 border-white hover:border-white transition-all duration-700 rounded-full"
                                image={image}
                                onClick={() => {
                                  const talksAbout = blog_post?.map(
                                    ({ category: { name, slug } }) => slug
                                  );
                                  const pTalksAbout = [...new Set(talksAbout)];
                                  setShownAuthor(name);
                                  setShownTagline(tagline);
                                  setShownTalksAbout(pTalksAbout);
                                }}
                                // onMouseLeave={() => {
                                //   setShownAuthor(null);
                                //   setShownTagline(null);
                                //   setShownTalksAbout([]);
                                // }}
                              />
                            </div>
                          </a>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* <Fade direction="left">
                  <div className="my-8 md:my-0 flex flex-col shadow-invButton p-2 rounded-md border-3 border-black">
                    <div className="flex flex-col">
                      <Text size="small">{shownAuthor}</Text>
                      <Text size="smaller" opaque>
                        {shownTagline}
                      </Text>
                    </div>
                    <div className="flex flex-row">
                      <Text size="small" opaque>
                        Di solito parla di{" "}
                      </Text>{" "}
                      {shownTalksAbout?.map((v, index) => (
                        <div className="mx-1" key={index}>
                          <Link href={`/${v}`}>
                            <Text size="small" weight="bold">
                              {index === shownTalksAbout.length - 1
                                ? ` #${v} `
                                : ` #${v}, `}
                            </Text>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </Fade> */}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w-screen mb-8 md:mb-16 px-8 md:px-28 grid grid-cols-1 md:grid-cols-2 grid-rows-3">
        <div className="col-span-1 row-span-3">
          <section className="grid grid-cols-1 auto-rows-auto ">
            <div className="mb-8 md:mb-16">
              {filteredPosts.length > 1 ? (
                <PostPreview
                  {...filteredPosts[1]}
                  image={getImage(filteredPosts[1]?.image)}
                  version={""}
                />
              ) : (
                <Title size="small">Non ci sono altri post da mostrare.</Title>
              )}
            </div>
          </section>
        </div>

        {filteredPosts.length > 4 &&
          filteredPosts.slice(2, 5).map((post, index) => {
            const pImage = getImage(post?.image);
            return (
              <div
                className="block col-span-1 md:pl-32 mb-8 md:pr-4 row-span-1"
                key={index}
              >
                <PostPreview {...post} image={pImage} version={"sidebar"} />
              </div>
            );
          })}
      </div>
      <div className="w-screen mb-8 md:mb-16 px-8 md:px-28 grid grid-cols-1 md:grid-cols-3 auto-rows-auto gap-x-16">
        {filteredPosts.slice(5).map((post, index) => {
          const pImage = getImage(post?.image);
          return (
            <div
              className={`md:block md:col-span-1 row-span-1 mb-8`}
              key={index}
            >
              <PostPreview {...post} image={pImage} version={""} />
            </div>
          );
        })}
      </div>
      <div className="px-8 md:px-12 mb-16">
        <div className="flex justify-around md:justify-center items-center">
          <div className="mx-2 md:mx-8">
            {currentPage - 1 > 0 && (
              <Button>
                <Link
                  className="text-xs leading-tight"
                  to={`/blog/${currentPage - 1 === 1 ? "" : currentPage - 1}`}
                >
                  Vai a pagina {currentPage - 1 === 1 ? "1" : currentPage - 1}
                </Link>
              </Button>
            )}
          </div>
          {/* <div className="mx-2">
            {renderPreviousPages(currentPage, numPages)}
          </div> */}
          <div className="mx-2">
            <span className="bg-black text-white p-2 rounded-md">
              {currentPage}
            </span>{" "}
            di {numPages}
          </div>
          {/* <div className="mx-2">{renderNextPages(currentPage, numPages)}</div> */}
          <div className="mx-2 md:mx-8">
            {currentPage + 1 <= numPages && (
              <Button>
                <Link className="text-xs" to={`/blog/${currentPage + 1}`}>
                  Neeeext
                </Link>
              </Button>
            )}
          </div>
        </div>
      </div>
    </CleanLayout>
  );
};

export default BlogList;

export const blogListQuery = graphql`
  query getPostsPaginated($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "it" } }
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        slug
        id
        title
        createdAt
        image {
          gatsbyImageData
        }
        imageWithoutTitle {
          gatsbyImageData
        }
        author {
          name
          tagline
          picture {
            gatsbyImageData
          }
        }
        category {
          color
          name
          slug
        }
        subtitle {
          subtitle
        }
      }
    }
    allContentfulCategory(filter: { node_locale: { eq: "it" } }) {
      nodes {
        slug
        color
        name
      }
    }
    allContentfulAuthor(filter: { node_locale: { eq: "it" } }) {
      nodes {
        blog_post {
          category {
            slug
          }
        }
        slug
        name
        tagline
        picture {
          gatsbyImageData
        }
      }
    }
  }
`;
